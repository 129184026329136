import { useEffect, useState } from "react";
import {
  getAnnualInterestRate,
  getBaseDebtAndInterest,
  getMinCollateralRatio,
  getUserCollateralWorthInFUSD,
  getLoanLiquidationThreshold,
  getUserCollateralRatio,
  getUserTokenBalances,
  isTokenApprovedForSpend,
  isFUSDApprovedForSpend,
  getMaxCollateralToWithdraw,
  getMaxFUSDToBorrow,
  getMaxTokensToWithdraw
} from "../web3Files/fusdFiles/web3ReadFunctions";

import {
  approveSpend,
  approveSpendForFUSD,
  borrowFUSDForUser,
  depositUserToken,
  payOffAllUserDebt,
  payOffUserDebt,
  withdrawUserToken
} from "../web3Files/fusdFiles/web3WriteFunctions.";
import { truncValue } from "../web3Files/globalFunctions";




const LandingPage = ({ switchedNetwork, telosPrice, walletBalance }) => {

  const [modalForm, setModalForm] = useState(null)
  const [inputFieldValues, setInputFieldValues] = useState({
          transactionAmount: '',
          transactionAmountValue: 0
        }),
        [wTLOSApprovedSpendAmount, setWTLOSApprovedSpendAmount] = useState(0),
        [FUSDApprovedSpendAmount, setFUSDApprovedSpendAmount] = useState(0),
        [annualInterestRate, setAnnualInterestRate] = useState(0),
        [minCollateralRatio, setMinCollateralRatio] = useState(0),
        [maxWithdrawableCollateral, setMaxWithdrawableCollateral] = useState(0),
        [maxBorrowableAmount, setMaxBorrowableAmount] = useState(0),
        [maxWithdrawableAmount, setMaxWithdrawableAmount] = useState([0, 'WC']),
        [userCollateralRatio, setUserCollateralRatio] = useState(0),
        [liquidationThreshold, setLiquidationThreshold] = useState(0),
        [userCollateralValue, setUserCollateralValue] = useState(0),
        [userCollateralLocked, setUserCollateralLocked] = useState([0, walletBalance.wTLOSTokenSymbol]),
        [userDebtProfile, setUserDebtProfile] = useState([0, 0]),
        liquidationBar = ((liquidationThreshold / userCollateralRatio) * 100) > 100 ? 100 : (liquidationThreshold / userCollateralRatio) * 100,
        liquidationBarValue = liquidationBar || 0



  useEffect(() => {
    if (switchedNetwork.status === true) {
      loadAllValuesFromSC()
    }
  }, [switchedNetwork.status])
  



  const updateInputField = (e) => {
    if(e.target.value > -1 || e.target.value === "") {
      setInputFieldValues(previousState => ({
        ...previousState, 
        transactionAmountValue: e.target.value * (modalForm === 'deposit'? telosPrice : 1),
        transactionAmount: e.target.value
      }))
    }
  }
  



  const setFieldToMaxValue = () => {
    let maxValue;

    if (modalForm === 'deposit') {
      maxValue = wTLOSApprovedSpendAmount.toString()
    } else if (modalForm === 'borrow') {
      maxValue = truncValue(maxBorrowableAmount, 4).toString()
    } else if (modalForm === 'withdraw') {
      maxValue = truncValue(maxWithdrawableAmount[0], 4).toString()
    }

    setInputFieldValues(previousState => ({
      ...previousState, 
      transactionAmountValue: maxValue * (modalForm === 'deposit'? telosPrice : 1),
      transactionAmount: maxValue
    }))
  }




  const updateUIAndCloseModal = () => {
    setModalForm(null)
    setInputFieldValues({
      transactionAmountValue: 0,
      transactionAmount: ''
    })
    loadAllValuesFromSC()
  }



  const loadAllValuesFromSC = () => {
    isTokenApprovedForSpend().then(approvedAmount => setWTLOSApprovedSpendAmount(approvedAmount))
    isFUSDApprovedForSpend().then(approvedAmount => setFUSDApprovedSpendAmount(approvedAmount))

    getMinCollateralRatio().then(minRatio => setMinCollateralRatio(minRatio))
    getAnnualInterestRate().then(interestRate => setAnnualInterestRate(interestRate))
    getLoanLiquidationThreshold().then(threshold => setLiquidationThreshold(threshold))
    getUserCollateralRatio().then(collateralRatio => setUserCollateralRatio(collateralRatio))
    getBaseDebtAndInterest().then(debtProfile => setUserDebtProfile(debtProfile))
    getUserTokenBalances().then(collateralLocked => setUserCollateralLocked(collateralLocked))

    getUserCollateralWorthInFUSD().then(collatralValue => setUserCollateralValue(collatralValue))
    getMaxCollateralToWithdraw().then(withdrawableCollateral => setMaxWithdrawableCollateral(withdrawableCollateral))
    getMaxFUSDToBorrow().then(borrowableAmount => setMaxBorrowableAmount(borrowableAmount))
    getMaxTokensToWithdraw().then(withdrawableAmount => setMaxWithdrawableAmount(withdrawableAmount))
  }







  return (
    <div className="w-full max-w-4xl mx-auto px-4 py-8">
      <section className="grid grid-cols-12 gap-2 rounded-md bg-neutral-950 px-2 py-1 mb-8">
        {wTLOSApprovedSpendAmount > 0 ?
          <aside className="col-span-6 sm:col-span-3 bg-black shadow shadow-force-700 rounded-sm py-4 sm:py-2 text-center cursor-pointer w-full" onClick={() => setModalForm('deposit')}>
            Deposit Collateral
          </aside>
        :
          <aside className="col-span-6 sm:col-span-3 bg-black shadow shadow-force-700 rounded-sm py-4 sm:py-2 text-center cursor-pointer" onClick={() => setModalForm('approve')}>
            Approve Deposit
          </aside>
        }

        <aside className="col-span-6 sm:col-span-3 bg-black shadow shadow-force-700 rounded-sm py-4 sm:py-2 text-center cursor-pointer" onClick={() => setModalForm('borrow')}>
          Borrow FUSD
        </aside>

        <aside className="col-span-6 sm:col-span-3 bg-black shadow shadow-force-700 rounded-sm py-4 sm:py-2 text-center cursor-pointer" onClick={() => setModalForm('repay')}>
          Repay Loan
        </aside>
        
        <aside className="col-span-6 sm:col-span-3 bg-black shadow shadow-force-700 rounded-sm py-4 sm:py-2 text-center cursor-pointer" onClick={() => setModalForm('withdraw')}>
          Withdraw Collateral
        </aside>
      </section>


      <section className="bg-neutral-950 rounded-lg flex flex-col divide-y divide-gray-900 space-y-10">
        <section className="px-2">
          <div className="flex justify-end gap-x-1 text-sm px-1">
            <h6 className="text-gray-400">Liquidation:</h6>
            <div className="">
              <h6 className="text-xs font-medium">{truncValue(liquidationThreshold, 2)}</h6>
              <h6 className="text-xxs leading-none text-right"><i className="bi bi-caret-down-fill"></i></h6>
            </div>
          </div>

          <div className="relative w-full rounded h-1 bg-gradient-to-r from-green-500 from-50% via-yellow-500 to-red-600">
            <div style={{ width:`${truncValue(liquidationBarValue, 1)}%` }} className={`h-full relative after:h-4 after:w-0.5 after:bg-white after:rounded-full after:absolute after:-top-1.5 after:right-0 text-right transition-all duration-300`}>
              <h6 className="pt-1"> {truncValue(liquidationBarValue, 1)} </h6>
            </div>
          </div>
        </section>
        
        <section className="flex-1 grid grid-cols-12 gap-y-10 sm:gap-x-10 text-4xl py-6 px-4 sm:px-10">
          <div className="col-span-6 sm:col-span-4">
            <h6 className="text-sm text-gray-400"> Principal Debt </h6>
            <h6 className="">{truncValue(userDebtProfile[0], 2)} <span className="text-lg">FUSD</span></h6>
          </div>
          
          <div className="col-span-6 sm:col-span-4">
            <h6 className="text-sm text-gray-400"> Borrowable Amount </h6>
            <h6 className="">{truncValue(maxBorrowableAmount, 2)} <span className="text-lg">FUSD</span></h6>
          </div>
          
          <div className="col-span-6 sm:col-span-4">
            <h6 className="text-sm text-gray-400"> Interest Accrued </h6>
            <h6 className="">{truncValue(userDebtProfile[1], 2)} <span className="text-lg">FUSD</span></h6>
          </div>
          
          <div className="col-span-6 sm:col-span-4">
            <h6 className="text-sm text-gray-400"> Collateral Deposited </h6>
            <h6 className="">{truncValue(userCollateralLocked[0], 2)} <span className="text-lg">{userCollateralLocked[1]}</span></h6>
          </div>

          <div className="col-span-6 sm:col-span-4">
            <h6 className="text-sm text-gray-400"> Collateral Value </h6>
            <h6 className=""> {truncValue((userCollateralValue), 2)} <span className="text-lg">USD</span> </h6>
          </div>

          <div className="col-span-6 sm:col-span-4">
            <h6 className="text-sm text-gray-400"> Withdrawable Amount </h6>
            <h6 className=""> {truncValue((maxWithdrawableAmount[0]), 2)} <span className="text-lg">{maxWithdrawableAmount[1]}</span> </h6>
          </div>
        </section>

        <section className="grid grid-cols-12 gap-y-10 sm:gap-x-10 text-xl py-6 px-4 sm:px-10">
          <div className="col-span-6 sm:col-span-3">
            <h6 className="text-sm text-gray-400"> Interest Rate </h6>
            <h6 className="">{truncValue((annualInterestRate / 10), 2)}%</h6>
          </div>

          <div className="col-span-6 sm:col-span-3">
            <h6 className="text-sm text-gray-400"> Min. Collateral </h6>
            <h6 className="">{truncValue((minCollateralRatio / 10), 2)}%</h6>
          </div>

          <div className="col-span-6 sm:col-span-3">
            <h6 className="text-sm text-gray-400"> Withdrawable Collateral </h6>
            <h6 className="">{truncValue(maxWithdrawableCollateral, 2)} <span className="text-lg">USD</span></h6>
          </div>

          <div className="col-span-6 sm:col-span-3">
            <h6 className="text-sm text-gray-400"> TLOS Price </h6>
            <h6 className="">{truncValue(telosPrice, 3)} <span className="text-lg">USD</span></h6>
          </div>
        </section>
      </section>
      


      {/* BEGINNING OF MODAL FORM SECTION */}
      <section className={`${modalForm !== null ? 'scale-y-100 translate-x-0' : 'scale-y-0 -translate-y-1/2'} transition-all duration-150 fixed top-0 left-0 w-screen h-screen flex items-center justify-center`}>
        <div className="w-full max-w-sm bg-black z-20 flex flex-col rounded-md overflow-hidden">
          <section className="flex items-center justify-between px-4 py-1.5 border-b">
            <h6 className="uppercase font-medium"> {modalForm} </h6>

            <div className="text-3xl cursor-pointer" onClick={updateUIAndCloseModal}> <i className="bi bi-x"></i> </div>
          </section>
          
          <section className="flex-1 py-8">
            {wTLOSApprovedSpendAmount > 0 || modalForm !== 'approve' ?
              (FUSDApprovedSpendAmount <= 0 && modalForm === 'repay' ? 
                <aside className="h-full flex items-center justify-center px-8">
                  <div className="space-y-6">
                    <p className="">
                      Great news! Your loan repayment process is just a step away. Kindly approve spending FUSD on the contract to initiate the repayment. Let's clear that debt! 💸🔒
                    </p>

                    <div className="space-y-2">
                      <button className="bg-force-700 w-full py-2.5 rounded-lg"
                        onClick={() => approveSpendForFUSD().then(() => updateUIAndCloseModal())}
                      >Approve FUSD</button>

                      <h6 className="text-sm">FUSD Balance: {truncValue(walletBalance.fusdStandardTokenBalance, 2)}</h6>
                    </div>
                  </div>
                </aside>
              :
                <aside className={`px-4 divide-y-2 divide-neutral-500`}>
                  <div className="relative pb-6">
                    <div className="flex items-center justify-between">
                      <label className="text-sm font-medium capitalize">Amount to {modalForm}</label>

                      <label className={`px-3 font-medium text-neutral-400 text-sm cursor-pointer ${modalForm === 'repay' && 'hidden'}`} onClick={setFieldToMaxValue}> max </label>
                    </div>
                    
                    <div className="bg-black border border-neutral-600 rounded mt-1">
                      <input type="text" name="transactionAmount" className="bg-transparent px-3 py-2 w-full text-xl" placeholder={`Amount to ${modalForm}`}
                        value={inputFieldValues.transactionAmount}
                        onChange={updateInputField}
                      />
                      
                      <label className="px-3 font-medium text-neutral-400 text-sm py-1"> ~ {truncValue(inputFieldValues.transactionAmountValue, 2).toLocaleString()} USD </label>
                    </div>
                    
                    {/* BEGINNING OF INSUFFICIENT ALLOWANCE FOR WC */}
                    <div className="">
                      {modalForm === 'deposit' &&
                        wTLOSApprovedSpendAmount < inputFieldValues.transactionAmount && 
                          <div className="text-xs">
                            Insufficient approved amount
                            <span className="underline underline-offset-4 font-medium ml-1.5 cursor-pointer"
                              onClick={() => approveSpend().then(() => loadAllValuesFromSC())}
                            >Approve More</span>
                          </div>
                      }
                    </div>
                    {/* END OF INSUFFICIENT ALLOWANCE FOR WC */}

                    
                    {/* BEGINNING OF INSUFFICIENT ALLOWANCE FOR FUSD REPAYMENT */}
                    <div className="">
                      {modalForm === 'repay' &&
                        FUSDApprovedSpendAmount < inputFieldValues.transactionAmount && 
                          <div className="text-xs">
                            Insufficient approved amount
                            <span className="underline underline-offset-4 font-medium ml-1.5 cursor-pointer"
                              onClick={() => approveSpendForFUSD().then(() => loadAllValuesFromSC())}
                            >Approve More</span>
                          </div>
                      }
                    </div>
                    {/* END OF INSUFFICIENT ALLOWANCE FOR FUSD REPAYMENT */}

                    
                    {/* BEGINNING OF INSUFFICIENT ALLOWANCE FOR FUSD WITHDRAWAL */}
                    <div className="">
                      {modalForm === 'withdraw' &&
                        inputFieldValues.transactionAmount > truncValue(maxWithdrawableAmount[0], 4) && 
                          <div className="text-xs">
                            Entered amount exceeds the max amount you can withdraw
                          </div>
                      }
                    </div>
                    {/* END OF INSUFFICIENT ALLOWANCE FOR FUSD WITHDRAWAL */}
                  </div>

                  <div className="pt-6 flex items-center justify-between gap-x-3">
                    {modalForm === 'deposit' &&
                      <button className="bg-force-700 w-full py-2.5 rounded"
                        onClick={() => depositUserToken(inputFieldValues.transactionAmount).then(() => updateUIAndCloseModal())}
                      >Deposit WTLOS</button>
                    }

                    {modalForm === 'borrow' &&
                      <button className="bg-force-700 w-full py-2.5 rounded"
                        onClick={() => borrowFUSDForUser(inputFieldValues.transactionAmount).then(() => updateUIAndCloseModal())}
                      >Borrow FUSD</button>
                    }
                    
                    {modalForm === 'withdraw' &&
                      <button className="bg-force-700 w-full py-2.5 rounded"
                        onClick={() => withdrawUserToken(inputFieldValues.transactionAmount).then(() => updateUIAndCloseModal())}
                      >Withdraw WTLOS</button>
                    }
                    
                    {modalForm === 'repay' &&
                      <>
                        <button className="border border-force-700 w-full py-2.5 rounded"
                          onClick={() => payOffAllUserDebt().then(() => updateUIAndCloseModal())}
                        >Repay All Loan</button>

                        <button className="bg-force-700 w-full py-2.5 rounded"
                          onClick={() => payOffUserDebt(inputFieldValues.transactionAmount).then(() => updateUIAndCloseModal())}
                        >Repay Loan</button>
                      </>
                    }
                  </div>
                </aside>
              )
            :
              <aside className="h-full flex items-center justify-center px-8">
                <div className="space-y-6">
                  <p className="">
                    Before you can proceed with deposits and borrowing, please take a moment to approve spending wTLOS on the contract. This step ensures a seamless and secure experience. Let's get started! 💰🔒
                  </p>

                  <div className="space-y-2">
                    <button className="bg-force-700 w-full py-2.5 rounded-lg"
                      onClick={() => approveSpend().then(() => updateUIAndCloseModal())}
                    >Approve WTLOS</button>

                    <h6 className="text-sm">wTLOS Balance: {(walletBalance.networkTokenBalance).toFixed(2)}</h6>
                  </div>
                </div>
              </aside>
            }
          </section>
        </div>

        <div className={`${modalForm !== null ? 'w-full h-full' : 'w-0 h-0'} bg-zinc-500/30 absolute top-0 left-0`} onClick={updateUIAndCloseModal}></div>
      </section>
      {/* END OF MODAL FORM SECTION */}
    </div>
  )
}

export default LandingPage