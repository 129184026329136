



const tokens = [
  { logo: "https://cryptologos.cc/logos/tron-trx-logo.png",
    name: "Tron",
    symbol: "TRX",
    balance: 23000,
  },
  { logo: "https://cryptologos.cc/logos/bnb-bnb-logo.png?v=025",
    name: "BNB",
    symbol: "BNB",
    balance: 2000,
  },
  { logo: "https://cryptologos.cc/logos/polygon-matic-logo.png?v=025",
    name: "Polygon",
    symbol: "MATIC",
    balance: 230000,
  },
  { logo: "https://cryptologos.cc/logos/usd-coin-usdc-logo.png?v=025",
    name: "USD Coin",
    symbol: "USDC",
    balance: 12000,
  },
  { logo: "https://cryptologos.cc/logos/eos-eos-logo.png?v=025",
    name: "EOS",
    symbol: "EOS",
    balance: 10000,
  },
  { logo: "https://cryptologos.cc/logos/polkadot-new-dot-logo.png?v=025",
    name: "Polkadot",
    symbol: "DOT",
    balance: 3000,
  },
  { logo: "https://cryptologos.cc/logos/solana-sol-logo.png?v=025",
    name: "Solana",
    symbol: "SOL",
    balance: 15000,
  },
  { logo: "https://cryptologos.cc/logos/stellar-xlm-logo.png?v=025",
    name: "Stellar",
    symbol: "XLM",
    balance: 5000,
  },
  { logo: "https://cryptologos.cc/logos/algorand-algo-logo.png?v=025",
    name: "Algorand",
    symbol: "ALGO",
    balance: 1000,
  },
  { logo: "https://cryptologos.cc/logos/near-protocol-near-logo.png?v=025",
    name: "NEAR Protocol",
    symbol: "NEAR",
    balance: 75000,
  },
  { logo: "https://cryptologos.cc/logos/monero-xmr-logo.png?v=025",
    name: "Monero",
    symbol: "XMR",
    balance: 13000,
  },
  { logo: "https://cryptologos.cc/logos/uniswap-uni-logo.png?v=025",
    name: "Uniswap",
    symbol: "UNI",
    balance: 7500,
  },
  { logo: "https://cryptologos.cc/logos/chainlink-link-logo.png?v=025",
    name: "Chainlink",
    symbol: "LINK",
    balance: 7500,
  },
  { logo: "https://cryptologos.cc/logos/ethereum-eth-logo.png?v=025",
    name: "Ethereum",
    symbol: "ETH",
    balance: 600,
  },
  { logo: "https://cryptologos.cc/logos/cosmos-atom-logo.png?v=025",
    name: "Cosmos",
    symbol: "ATOM",
    balance: 700,
  },
  { logo: "https://cryptologos.cc/logos/binance-usd-busd-logo.png?v=025",
    name: "Binance USD",
    symbol: "BUSD",
    balance: 9200,
  },
]




const MarketPage = () => {
  return (
    <section className="h-full overflow-auto px-2 sm:p-6 space-y-8">
      {/* <div className="bg-gradient-to-tr from-black via-black to-force-dark rounded-lg px-4 py-8 sm:px-10 space-y-8"> */}
      <div className="bg-black to-force-dark rounded-lg px-4 py-6 sm:px-10 grid grid-cols-12 divide-x">
        <aside className="col-span-4">
          <h6 className="text-sm sm:text-base text-gray-400"> Total Market Size </h6>
          <h6 className="text-xl sm:text-2xl">$713.34M</h6>
        </aside>
        
        <aside className="col-span-4 pl-4 sm:pl-8">
          <h6 className="text-sm sm:text-base text-gray-400"> Total Available </h6>
          <h6 className="text-xl sm:text-2xl">$453.34M</h6>
        </aside>

        <aside className="col-span-4 pl-4 sm:pl-8">
          <h6 className="text-sm sm:text-base text-gray-400"> Total Borrows </h6>
          <h6 className="text-xl sm:text-2xl">$260M</h6>
        </aside>
      </div>

      <div className="pb-28">
        <div className="bg-black rounded-md py-4 flex flex-col sm:max-h-[140vh] overflow-hidden">
          <section className="grid grid-cols-12 text-sm px-2 py-1 pb-2">
            <aside className="col-span-3 px-2">Asset</aside>
            <aside className="col-span-2 px-2">Total Supplied</aside>
            <aside className="col-span-2 px-2">Supply APY</aside>
            <aside className="col-span-2 px-2">Total Borrowed</aside>
            <aside className="col-span-2 px-2">Borrow APY</aside>
            <aside className="col-span-1 px-2">Collateral</aside>
          </section>

          <section className="flex-1 h-full overflow-auto">
            {tokens.map((token, index) => (
              <div key={index} className="grid grid-cols-12 text-sm py-3 px-2">
                <aside className="col-span-3 px-2 flex items-center gap-x-3">
                  <div className="rounded-full bg-white h-7 w-7 flex items-center justify-center">
                    <img src={token.logo} alt={token.name} className="h-4" />
                  </div>

                  <div className="">
                    <h5 className="text-base">{token.name}</h5>
                    <h5 className="text-xs text-gray-500">{token.symbol}</h5>
                  </div>
                </aside>

                <aside className="col-span-2 px-2">{(token.balance).toLocaleString()} {token.symbol}</aside>

                <aside className="col-span-2 px-2">6.14%</aside>
                <aside className="col-span-2 px-2">{(token.balance).toLocaleString()} {token.symbol}</aside>
                <aside className="col-span-2 px-2">6.14%</aside>
                
                <aside className="col-span-1 px-2">30%</aside>
              </div>
            ))}
          </section>
        </div>
      </div>
    </section>
  )
}

export default MarketPage