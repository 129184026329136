import { useEffect, useState } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  checkIfMetamaskIsInstalled,
  checkWalletChainAndSwitch,
  connectToMetamask,
  getConnectedWalletAddress,
  getConnectedWalletBalance
} from "./web3Files/globalFunctions";



import LandingPage from "./pages/LandingPage";
import MarketPage from "./pages/MarketPage";
import HistoryPage from "./pages/HistoryPage";
import { getTokenPriceInFUSD } from "./web3Files/fusdFiles/web3ReadFunctions";






function App() {

  // const chainInfo = { hex: '0x29', title: 'Telos', symbol: 'TLOS' } // TESTNET
  const chainInfo = { hex: '0x28', title: 'Telos', symbol: 'TLOS' } // MAINNET
  const [connectedHashWallet, setConnectedHashWallet] = useState('Connect Wallet'),
        [telosPrice, setTelosPrice] = useState(0),
        [walletBalance, setWalletBalance] = useState({
          networkTokenBalance: 0,
          wTLOSTokenBalance: 0,
          wTLOSTokenSymbol: 'WC',
          fusdStandardTokenBalance: 0,
          fusdStandardTokenSymbol: 'FUSD',
        }),
        [switchedNetwork, setSwitchedNetwork] = useState({
          status: false,
          portfolioMessage: `Please connect to the ${chainInfo.title} mainnet for events in your portfolio`,
          eventMessage: `Please connect to the ${chainInfo.title} mainnet for information about events`,
          teamMessage: `Connect to ${chainInfo.title} mainnet for sale information of team`,
        })




  useEffect(() => {
    checkIfMetamaskIsInstalled().then(installed => {
      if (installed === true) {
        window.ethereum.on('chainChanged', (_chainId) => {
          if (typeof _chainId !== 'undefined') {
            if (_chainId !== chainInfo.hex) { /* METAMASK IS NOT ON THE PREFERRED NETWORK */
              setSwitchedNetwork(previousState => ({...previousState, status: false}))
              return true;
            }
          }
          setSwitchedNetwork(previousState => ({...previousState, status: true}))
          return false;
        });

        
        return getConnectedWalletAddress().then(account => {
          if (typeof account !== 'boolean') {
            setConnectedHashWallet(`${account.slice(0, 6)}......${account.slice(account.length - 4, account.length)}`.toLowerCase());

            return checkWalletChainAndSwitch(chainInfo.hex).then(connected => {
              console.log('Network Switched', connected)
              if (connected === true) {
                getConnectedWalletBalance().then(balance => setWalletBalance(balance))
                setSwitchedNetwork(previousState => ({...previousState, status: true}))
                return
              }
            })
          }
        })
      }

      return;
    })

    getTokenPriceInFUSD().then(priceInFUSD => setTelosPrice(priceInFUSD))

    const wTLOSPriceUpdateInterval = setInterval(() => {
      getTokenPriceInFUSD().then(priceInFUSD => setTelosPrice(priceInFUSD))
    }, 30000);

    return () => clearInterval(wTLOSPriceUpdateInterval)
  }, [chainInfo.hex])
  








  const connectToWallet = async () => {
    connectToMetamask().then(metamaskData => {
      if (typeof metamaskData === typeof true) {
        alert("Metamask is not installed")
        return;
      }

      return checkWalletChainAndSwitch(chainInfo.hex).then(connected => {
        setConnectedHashWallet(metamaskData.hashedAccount)

        console.log('Network Switched', connected)
        if (connected === true) {
          setSwitchedNetwork(previousState => ({...previousState, status: true}))
          return
        }
      })
    });
  }









  return (
    <div className="bg-zinc-800 text-white w-full min-h-screen" style={{ background:"url('/full logo.jpg')", backgroundColor:'rgba(39, 39, 42, 0.4)', backgroundBlendMode:'overlay', backgroundRepeat:"no-repeat", backgroundSize:"cover", backgroundPosition:"center" }}>
      <section className="sticky top-0 bg-neutral-900">
        <section className="w-full max-w-7xl mx-auto flex items-center justify-between py-2 px-4">
          <img src="/logo_text.png" alt="Force" className="h-12" />
          {/* <h1 className="text-4xl order-1 sm:order-2">FORCE</h1> */}

          <button className="text-base bg-force-300 text-force-950 font-medium rounded-full px-4 py-1.5 order-2 sm:order-3"
            onClick={connectToWallet}
          >{connectedHashWallet}</button>
        </section>
      </section>

      <BrowserRouter>
        <Routes>

          <Route
            path="/"
            element={<LandingPage
              switchedNetwork={switchedNetwork}
              walletBalance={walletBalance}
              telosPrice={telosPrice}
            />}
          />

          <Route
            path="/market"
            element={<MarketPage />}
          />

          <Route
            path="/history"
            element={<HistoryPage />}
          />

        </Routes>
      </BrowserRouter>

      <ToastContainer position="bottom-left" theme="colored" />
    </div>
  );
}

export default App;
