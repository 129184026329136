import { toast } from "react-toastify";
import { FUSD_SALE_ABI, FUSD_SALE_ADDRESS, FUSD_TOKEN_ADDRESS, wTLOS_TOKEN_ABI, wTLOS_TOKEN_ADDRESS } from "../coreVariables";
import { getConnectedWalletAddress, getTokenSymbol, initContract, web3 } from "../globalFunctions";






export const approveSpend = async () => {
  const account = await getConnectedWalletAddress(),
        tokenContract = await initContract(wTLOS_TOKEN_ABI, wTLOS_TOKEN_ADDRESS),
        approveAmount = web3.utils.toWei("759382837472382317", 'ether');



  // try {
  //   tokenContract.methods.approve(FUSD_SALE_ADDRESS, approveAmount)
  //   .send({
  //     from: account
  //   })
  //   .once("transactionHash", (hash) => {
  //     toast.info("Your approve spend transaction is pending confirmation on the block");
  //     console.log("transactionHash", hash);
  //   })
  //   .on('receipt', (receipt) => {
  //     toast.success('Approve spend transaction successfully mined!')
  //     console.info('Transaction Receipt', receipt);

  //     return true;
  //   })
  //   .once('error', (approveSpendCatchError) => {
  //     toast.error(approveSpendCatchError.message)
  //     // console.info('approveSpendCatchError', approveSpendCatchError.message)
  //     return false;
  //   })
  // } catch (approveSpendError) {
  //   toast.error(approveSpendError.message)
  //   console.info('approveSpendError', approveSpendError.message)
  //   return false
  // }


  return new Promise((resolve, reject) => {
    tokenContract.methods.approve(FUSD_SALE_ADDRESS, approveAmount)
    .send({
      from: account
    })
    .once("transactionHash", (hash) => {
      toast.info("Your approve spend transaction is pending confirmation on the block");
      console.log("transactionHash", hash);
    })
    .on('receipt', (receipt) => {
      toast.success('Approve spend transaction successfully mined!')
      console.info('Transaction Receipt', receipt);

      return true;
    })
    .once('confirmation', (receipt) => {
      toast.success("Your transaction is confirmed! ");
      console.info(receipt);

      return resolve(true);
    })
    .on('error', (approveSpendCatchError) => {
      toast.error(approveSpendCatchError.message)
      console.log(approveSpendCatchError);

      return reject(false)
    })
  }).catch(approveSpendError => {
    toast.error(approveSpendError.message)
    console.info('approveSpendError', approveSpendError.message)
    return false
  });
}




export const approveSpendForFUSD = async () => {
  const account = await getConnectedWalletAddress(),
        tokenContract = await initContract(wTLOS_TOKEN_ABI, FUSD_TOKEN_ADDRESS),
        approveAmount = web3.utils.toWei('759382837472382317', 'ether');



  // try {
  //   tokenContract.methods.approve(FUSD_SALE_ADDRESS, approveAmount)
  //   .send({
  //     from: account
  //   })
  //   .once("transactionHash", (hash) => {
  //     toast.info("Your approve spend transaction is pending confirmation on the block");
  //     console.log("transactionHash", hash);
  //   })
  //   .on('receipt', (receipt) => {
  //     toast.success('Approve spend transaction successfully mined!')
  //     console.info('Transaction Receipt', receipt);

  //     return true;
  //   })
  //   .once('error', (approveSpendForFUSDCatchError) => {
  //     toast.error(approveSpendForFUSDCatchError.message)
  //     // console.info('approveSpendForFUSDCatchError', approveSpendForFUSDCatchError.message)
  //     return false;
  //   })
  // } catch (approveSpendForFUSDError) {
  //   toast.error(approveSpendForFUSDError.message)
  //   console.info('approveSpendForFUSDError', approveSpendForFUSDError.message)
  //   return false
  // }

  return new Promise((resolve, reject) => {
    tokenContract.methods.approve(FUSD_SALE_ADDRESS, approveAmount)
    .send({
      from: account
    })
    .once("transactionHash", (hash) => {
      toast.info("Your approve spend transaction is pending confirmation on the block");
      console.log("transactionHash", hash);
    })
    .on('receipt', (receipt) => {
      toast.success('Approve spend transaction successfully mined!')
      console.info('Transaction Receipt', receipt);

      return true;
    })
    .once('confirmation', (receipt) => {
      toast.success("Your transaction is confirmed! ");
      console.info(receipt);

      return resolve(true);
    })
    .on('error', (approveSpendForFUSDCatchError) => {
      toast.error(approveSpendForFUSDCatchError.message)
      console.log(approveSpendForFUSDCatchError);

      return reject(false)
    })
  }).catch(approveSpendForFUSDError => {
    toast.error(approveSpendForFUSDError.message)
    console.info('approveSpendForFUSDError', approveSpendForFUSDError.message)
    return false
  });
}




export const borrowFUSDForUser = async (amountToBorrow) => {
  const account = await getConnectedWalletAddress(),
        mainContract = await initContract(FUSD_SALE_ABI, FUSD_SALE_ADDRESS),
        FUSDAmountToBorrow = web3.utils.toWei(amountToBorrow, 'ether');

  // console.info('Main Contract:', mainContract);
  console.info('Amount to borrow:', amountToBorrow);


  return new Promise((resolve, reject) => {
    mainContract.methods.borrowFUSD(FUSDAmountToBorrow)
    .send({
      from: account,
      gas: 500000
    })
    .once("transactionHash", (hash) => {
      console.log("hash", hash);

      toast.info("Your transaction is pending confirmation on the block");
    })
    .once('receipt', (receipt) => {
      toast.success("Your transaction receipt is ready! ");
      console.info(receipt);
    })
    .once('confirmation', (receipt) => {
      toast.success("Your transaction is confirmed! ");
      console.info(receipt);

      return resolve(true);
    })
    .on('error', (err) => {
      toast.error(err.message)
      console.log(err);

      return reject(false)
    })
  }).catch(borrowFUSDForUserPromiseError => {
    console.log(borrowFUSDForUserPromiseError);
    toast.error(borrowFUSDForUserPromiseError.message)
    return false;
  });
}




export const depositUserToken = async (depositAmount) => {
  const account = await getConnectedWalletAddress(),
        tokenSymbol = await getTokenSymbol(wTLOS_TOKEN_ADDRESS),
        mainContract = await initContract(FUSD_SALE_ABI, FUSD_SALE_ADDRESS),
        wTLOSAmount = web3.utils.toWei(depositAmount, 'ether');

  // console.info('Main Contract:', mainContract);
  console.info('Amount to deposit:', depositAmount);


  return new Promise((resolve, reject) => {
    mainContract.methods.depositToken(tokenSymbol, wTLOSAmount)
      .send({
        from: account,
        gas: 500000
      })
      .once("transactionHash", (hash) => {
        console.log("hash", hash);

        toast.info("Your transaction is pending confirmation on the block");
      })
      .once('receipt', (receipt) => {
        toast.success("Your transaction receipt is ready! ");
        console.info(receipt);
      })
      .once('confirmation', (receipt) => {
        toast.success("Your transaction is confirmed! ");
        console.info(receipt);

        return resolve(true);
      })
      .on('error', (err) => {
        toast.error(err.message)
        console.log(err);

        return reject(false)
      })
  }).catch(depositUserTokenPromiseError => {
    console.log(depositUserTokenPromiseError);
    toast.error(depositUserTokenPromiseError.message)
    return false;
  });
}




export const depositUserTokenAndBorrowFUSD = async (TLOSAmount, fusdAmount) => {
  const account = await getConnectedWalletAddress(),
        mainContract = await initContract(FUSD_SALE_ABI, FUSD_SALE_ADDRESS),
        wTLOSAmount = web3.utils.toWei(TLOSAmount, 'ether'),
        FUSDAmount = web3.utils.toWei(fusdAmount, 'ether');

  // console.info('Main Contract:', mainContract);
  console.info('TLOS amount to pay:', TLOSAmount);
  console.info('FUSD amount to pay:', fusdAmount);


  return new Promise((resolve, reject) => {
    mainContract.methods.depositTokenAndBorrowFUSD(FUSD_TOKEN_ADDRESS, wTLOSAmount, FUSDAmount)
      .send({
        from: account,
        gas: 500000
      })
      .once("transactionHash", (hash) => {
        console.log("hash", hash);

        toast.info("Your transaction is pending confirmation on the block");
      })
      .once('receipt', (receipt) => {
        toast.success("Your transaction receipt is ready! ");
        console.info(receipt);
      })
      .once('confirmation', (receipt) => {
        toast.success("Your transaction is confirmed! ");
        console.info(receipt);

        return resolve(true);
      })
      .on('error', (err) => {
        toast.error(err.message)
        console.log(err);

        return reject(false)
      })
  }).catch(depositUserTokenAndBorrowFUSDPromiseError => {
    console.log(depositUserTokenAndBorrowFUSDPromiseError);
    toast.error(depositUserTokenAndBorrowFUSDPromiseError.message)
    return false;
  });
}




export const withdrawUserToken = async (withdrawalAmount) => {
  const account = await getConnectedWalletAddress(),
        tokenSymbol = await getTokenSymbol(wTLOS_TOKEN_ADDRESS),
        mainContract = await initContract(FUSD_SALE_ABI, FUSD_SALE_ADDRESS),
        amount = web3.utils.toWei(withdrawalAmount, 'ether');

  // console.info('Main Contract:', mainContract);
  console.info('Amount to withdraw:', withdrawalAmount);


  return new Promise((resolve, reject) => {
    mainContract.methods.withdrawToken(tokenSymbol, amount)
      .send({
        from: account,
        gas: 500000
      })
      .once("transactionHash", (hash) => {
        console.log("hash", hash);

        toast.info("Your transaction is pending confirmation on the block");
      })
      .once('receipt', (receipt) => {
        toast.success("Your transaction receipt is ready! ");
        console.info(receipt);
      })
      .once('confirmation', (receipt) => {
        toast.success("Your transaction is confirmed! ");
        console.info(receipt);

        return resolve(true);
      })
      .on('error', (err) => {
        toast.error(err.message)
        console.log(err);

        return reject(false)
      })
  }).catch(withdrawUserTokenPromiseError => {
    console.log(withdrawUserTokenPromiseError);
    toast.error(withdrawUserTokenPromiseError.message)
    return false;
  });
}




export const payOffUserDebt = async (debtAmount) => {
  const account = await getConnectedWalletAddress(),
        mainContract = await initContract(FUSD_SALE_ABI, FUSD_SALE_ADDRESS),
        amount = web3.utils.toWei(debtAmount, 'ether');

  // console.info('Main Contract:', mainContract);
  console.info('Amount to repay:', debtAmount);


  return new Promise((resolve, reject) => {
    mainContract.methods.payOffDebt(amount)
      .send({
        from: account,
        gas: 500000
      })
      .once("transactionHash", (hash) => {
        console.log("hash", hash);

        toast.info("Your transaction is pending confirmation on the block");
      })
      .once('receipt', (receipt) => {
        toast.success("Your transaction receipt is ready! ");
        console.info(receipt);
      })
      .once('confirmation', (receipt) => {
        toast.success("Your transaction is confirmed! ");
        console.info(receipt);

        return resolve(true);
      })
      .on('error', (err) => {
        toast.error(err.message)
        console.log(err);

        return reject(false)
      })
  }).catch(payOffUserDebtPromiseError => {
    console.log(payOffUserDebtPromiseError);
    toast.error(payOffUserDebtPromiseError.message)
    return false;
  });
}




export const payOffAllUserDebt = async () => {
  const account = await getConnectedWalletAddress(),
        mainContract = await initContract(FUSD_SALE_ABI, FUSD_SALE_ADDRESS);

  // console.info('Main Contract:', mainContract);


  return new Promise((resolve, reject) => {
    mainContract.methods.payOffAllDebt()
      .send({
        from: account,
        gas: 500000
      })
      .once("transactionHash", (hash) => {
        console.log("hash", hash);

        toast.info("Your transaction is pending confirmation on the block");
      })
      .once('receipt', (receipt) => {
        toast.success("Your transaction receipt is ready! ");
        console.info(receipt);
      })
      .once('confirmation', (receipt) => {
        toast.success("Your transaction is confirmed! ");
        console.info(receipt);

        return resolve(true);
      })
      .on('error', (err) => {
        toast.error(err.message)
        console.log(err);

        return reject(false)
      })
  }).catch(payOffAllUserDebtPromiseError => {
    console.log(payOffAllUserDebtPromiseError);
    toast.error(payOffAllUserDebtPromiseError.message)
    return false;
  });
}