import { FUSD_SALE_ABI, FUSD_SALE_ADDRESS, wTLOS_TOKEN_ADDRESS, wTLOS_TOKEN_ABI, networkDecimal_18, FUSD_TOKEN_ADDRESS } from "../coreVariables";
import { getConnectedWalletAddress, getTokenSymbol, initContract } from "../globalFunctions";



// READ FUNCTIONS
/*
  - getCollateralRatio(walletAddress)
  - getTotalDebt(walletAddress)
  - getUserCollateralWorthInFUSD(walletAddress)
  - tokenPriceInFUSD(tokenAddress, amount)
  - isDebtorBelowLiquidationThreshold(walletAddress)
  - getUserTokenBalances(walletAddress)
  - getTotalDebt(walletAddress)
*/


export const isTokenApprovedForSpend = async () => {
  try {
    const connectedWallet = await getConnectedWalletAddress();
    const contract = await initContract(wTLOS_TOKEN_ABI, wTLOS_TOKEN_ADDRESS);
    const allowance = await contract.methods.allowance(connectedWallet, FUSD_SALE_ADDRESS).call();

    
    return allowance / Math.pow(10, networkDecimal_18); // CONVERT BIGINT TO NUMBER
  } catch (isTokenApprovedForSpendError) {
    console.log("isTokenApprovedForSpendError", isTokenApprovedForSpendError.message);
  }
}





export const isFUSDApprovedForSpend = async () => {
  try {
    const connectedWallet = await getConnectedWalletAddress();
    const contract = await initContract(wTLOS_TOKEN_ABI, FUSD_TOKEN_ADDRESS);
    const allowance = await contract.methods.allowance(connectedWallet, FUSD_SALE_ADDRESS).call();


    return allowance / Math.pow(10, networkDecimal_18); // CONVERT BIGINT TO NUMBER
  } catch (isTokenApprovedForSpendError) {
    console.log("isTokenApprovedForSpendError", isTokenApprovedForSpendError.message);
  }
}






export const getMinCollateralRatio = async () => {
  try {
    const contract = await initContract(FUSD_SALE_ABI, FUSD_SALE_ADDRESS)
    const minCollateralRatio = await contract.methods.getMinCollateralRatioForLoanTenthPerc().call()

    // console.log("methods", contract.methods);
    // console.log("minCollateralRatio", minCollateralRatio);

    return Number(minCollateralRatio);
  } catch (getMinCollateralRatioError) {
    console.log("getMinCollateralRatioError", getMinCollateralRatioError.message);
  }
}






export const getAnnualInterestRate = async () => {
  try {
    const contract = await initContract(FUSD_SALE_ABI, FUSD_SALE_ADDRESS)
    const interestRate = await contract.methods.getAnnualInterestRateTenthPerc().call()

    // console.log("methods", contract.methods);
    // console.log("interestRate", interestRate);

    return Number(interestRate);
  } catch (getAnnualInterestRateError) {
    console.log("getAnnualInterestRateError", getAnnualInterestRateError.message);
  }
}






export const getMaxFUSDToBorrow = async () => {
  try {
    const account = await getConnectedWalletAddress()
    const contract = await initContract(FUSD_SALE_ABI, FUSD_SALE_ADDRESS)
    const maxBorrowableAmount = await contract.methods.calculateMaxFUSDToBorrow(account).call()

    // console.log("methods", contract.methods);
    // console.log("maxBorrowableAmount", maxBorrowableAmount);

    return maxBorrowableAmount / Math.pow(10, networkDecimal_18);
  } catch (getMaxFUSDToBorrowError) {
    console.log("getMaxFUSDToBorrowError", getMaxFUSDToBorrowError.message);
  }
}






export const getMaxCollateralToWithdraw = async () => {
  try {
    const account = await getConnectedWalletAddress()
    const contract = await initContract(FUSD_SALE_ABI, FUSD_SALE_ADDRESS)
    const maxWithdrawableCollateral = await contract.methods.calculateMaxCollateralToWithdraw(account).call()

    // console.log("methods", contract.methods);
    // console.log("maxWithdrawableCollateral", maxWithdrawableCollateral);

    return maxWithdrawableCollateral / Math.pow(10, networkDecimal_18);
  } catch (getMaxCollateralToWithdrawError) {
    console.log("getMaxCollateralToWithdrawError", getMaxCollateralToWithdrawError.message);
  }
}






export const getMaxTokensToWithdraw = async () => {
  try {
    const account = await getConnectedWalletAddress()
    const contract = await initContract(FUSD_SALE_ABI, FUSD_SALE_ADDRESS)
    const maxWithdrawableAmount = await contract.methods.calculateMaxTokensToWithdraw(account).call()

    // console.log("methods", contract.methods);
    // console.log("maxWithdrawableAmount", maxWithdrawableAmount);

    return [
      maxWithdrawableAmount[0] / Math.pow(10, networkDecimal_18),
      maxWithdrawableAmount[1]
    ];
  } catch (getMaxTokensToWithdrawError) {
    console.log("getMaxTokensToWithdrawError", getMaxTokensToWithdrawError.message);
  }
}






export const getLoanLiquidationThreshold = async () => {
  try {
    const contract = await initContract(FUSD_SALE_ABI, FUSD_SALE_ADDRESS)
    const liquidationThreshold = await contract.methods.getLiquidationThreshold().call()

    // console.log("methods", contract.methods);
    // console.log("liquidationThreshold", liquidationThreshold);

    return Number(liquidationThreshold);
  } catch (getLoanLiquidationThresholdError) {
    console.log("getLoanLiquidationThresholdError", getLoanLiquidationThresholdError.message);
  }
}






export const getUserCollateralRatio = async () => {
  try {
    const connectedWallet = await getConnectedWalletAddress()
    const contract = await initContract(FUSD_SALE_ABI, FUSD_SALE_ADDRESS)
    const userCollateralRatio = await contract.methods.getCollateralRatio(connectedWallet).call()

    // console.log("methods", contract.methods);
    // console.log("userCollateralRatio", userCollateralRatio);

    return Number(userCollateralRatio);
  } catch (getUserCollateralRatioError) {
    console.log("getUserCollateralRatioError", getUserCollateralRatioError.message);
  }
}






export const getBaseDebtAndInterest = async () => {
  try {
    const connectedWallet = await getConnectedWalletAddress()
    const contract = await initContract(FUSD_SALE_ABI, FUSD_SALE_ADDRESS)
    const baseDebtAndInterest = await contract.methods.calculateBaseDebtAndInterest(connectedWallet).call()

    // console.log("methods", contract.methods);
    // console.log("baseDebtAndInterest", baseDebtAndInterest);

    return [
      baseDebtAndInterest[0] / Math.pow(10, networkDecimal_18),
      baseDebtAndInterest[1] / Math.pow(10, networkDecimal_18)
    ];
  } catch (getBaseDebtAndInterestError) {
    console.log("getBaseDebtAndInterestError", getBaseDebtAndInterestError.message);
  }
}






export const getUserCollateralWorthInFUSD = async () => {
  try {
    const connectedWallet = await getConnectedWalletAddress()
    const contract = await initContract(FUSD_SALE_ABI, FUSD_SALE_ADDRESS)
    const userCollateralWorthInFUSD = await contract.methods.getUserCollateralWorthInFUSD(connectedWallet).call()

    // console.log("methods", contract.methods);
    // console.log("userCollateralWorthInFUSD", userCollateralWorthInFUSD);

    return userCollateralWorthInFUSD / Math.pow(10, networkDecimal_18);
  } catch (getUserCollateralWorthInFUSDError) {
    console.log("getUserCollateralWorthInFUSDError", getUserCollateralWorthInFUSDError.message);
  }
}






export const getUserTokenBalances = async () => {
  try {
    const connectedWallet = await getConnectedWalletAddress()
    const contract = await initContract(FUSD_SALE_ABI, FUSD_SALE_ADDRESS)
    const userTokenBalances = await contract.methods.getUserTokenBalances(connectedWallet).call()

    // console.log("methods", contract.methods);
    // console.log("userTokenBalances", userTokenBalances);

    // return userTokenBalances;
    return [
      userTokenBalances[0] / Math.pow(10, networkDecimal_18),
      userTokenBalances[1]
    ];
  } catch (getUserTokenBalancesError) {
    console.log("getUserTokenBalancesError", getUserTokenBalancesError.message);
  }
}






export const getTokenPriceInFUSD = async () => {
  try {
    const contract = await initContract(FUSD_SALE_ABI, FUSD_SALE_ADDRESS)
    const tokenSymbol = await getTokenSymbol(wTLOS_TOKEN_ADDRESS)
    const tokenPriceInFUSD = await contract.methods.tokenPriceInFUSD(tokenSymbol, 100000000000000).call()

    // console.log("methods", contract.methods);
    // console.log("tokenPriceInFUSD", tokenPriceInFUSD);

    // return userTokenBalances;
    return tokenPriceInFUSD / Math.pow(10, 14);
  } catch (getTokenPriceInFUSDError) {
    console.log("getTokenPriceInFUSDError", getTokenPriceInFUSDError.message);
  }
}