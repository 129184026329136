import { useState } from "react";



const tokens = [
  { logo: "https://cryptologos.cc/logos/tron-trx-logo.png",
    name: "Tron",
    symbol: "TRX",
    balance: 23000,
    type: "Borrow"
  },
  { logo: "https://cryptologos.cc/logos/bnb-bnb-logo.png?v=025",
    name: "BNB",
    symbol: "BNB",
    balance: 2000,
    type: "Redeem"
  },
  { logo: "https://cryptologos.cc/logos/polygon-matic-logo.png?v=025",
    name: "Polygon",
    symbol: "MATIC",
    balance: 230000,
    type: "Liquadate Borrow"
  },
  { logo: "https://cryptologos.cc/logos/usd-coin-usdc-logo.png?v=025",
    name: "USD Coin",
    symbol: "USDC",
    balance: 12000,
    type: "Borrow"
  },
  { logo: "https://cryptologos.cc/logos/eos-eos-logo.png?v=025",
    name: "EOS",
    symbol: "EOS",
    balance: 10000,
    type: "Liquadate Borrow"
  },
  { logo: "https://cryptologos.cc/logos/polkadot-new-dot-logo.png?v=025",
    name: "Polkadot",
    symbol: "DOT",
    balance: 3000,
    type: "Redeem"
  },
  { logo: "https://cryptologos.cc/logos/solana-sol-logo.png?v=025",
    name: "Solana",
    symbol: "SOL",
    balance: 15000,
    type: "Borrow"
  },
  { logo: "https://cryptologos.cc/logos/stellar-xlm-logo.png?v=025",
    name: "Stellar",
    symbol: "XLM",
    balance: 5000,
    type: "Liquadate Borrow"
  },
  { logo: "https://cryptologos.cc/logos/algorand-algo-logo.png?v=025",
    name: "Algorand",
    symbol: "ALGO",
    balance: 1000,
    type: "Borrow"
  },
  { logo: "https://cryptologos.cc/logos/near-protocol-near-logo.png?v=025",
    name: "NEAR Protocol",
    symbol: "NEAR",
    balance: 75000,
    type: "Redeem"
  },
  { logo: "https://cryptologos.cc/logos/monero-xmr-logo.png?v=025",
    name: "Monero",
    symbol: "XMR",
    balance: 13000,
    type: "Liquadate Borrow"
  },
  { logo: "https://cryptologos.cc/logos/uniswap-uni-logo.png?v=025",
    name: "Uniswap",
    symbol: "UNI",
    balance: 7500,
    type: "Redeem"
  },
  { logo: "https://cryptologos.cc/logos/chainlink-link-logo.png?v=025",
    name: "Chainlink",
    symbol: "LINK",
    balance: 7500,
    type: "Borrow"
  },
  { logo: "https://cryptologos.cc/logos/ethereum-eth-logo.png?v=025",
    name: "Ethereum",
    symbol: "ETH",
    balance: 600,
    type: "Liquadate Borrow"
  },
  { logo: "https://cryptologos.cc/logos/cosmos-atom-logo.png?v=025",
    name: "Cosmos",
    symbol: "ATOM",
    balance: 700,
    type: "Liquadate Borrow"
  },
  { logo: "https://cryptologos.cc/logos/binance-usd-busd-logo.png?v=025",
    name: "Binance USD",
    symbol: "BUSD",
    balance: 9200,
    type: "Borrow"
  },
]




const HistoryPage = () => {

  const [openDropDown, setOpenDropDown] = useState(false)
  const [dropDownValue, setDropDownValue] = useState('All')
  const [showOnlyMyTransactions, setShowOnlyMyTransactions] = useState(false)




  const updateDropdownValue = (value) => {
    setOpenDropDown(false)
    setDropDownValue(value)
  }




  return (
    <section className="h-full overflow-auto px-2 sm:p-6">

      <div className="pb-28">
        <div className="bg-black rounded-md py-4 flex flex-col sm:max-h-[140vh] overflow-hidden">
          <section className="flex items-center justify-between px-4 pt-3 pb-6">
            <div className="flex items-center gap-x-2 cursor-pointer" onClick={() => setShowOnlyMyTransactions(!showOnlyMyTransactions)}>
              <div className={`h-5 w-5 border border-force rounded flex items-center justify-center ${showOnlyMyTransactions === true && "bg-force text-black"}`}>
                {showOnlyMyTransactions === true && <i className="bi bi-check text-2xl leading-none"></i>}
              </div>

              <h6 className="text-sm">Show Only My Transactions</h6>
            </div>
            
            <div className="flex items-center gap-x-2 text-sm">
              <h6 className="">Type:</h6>

              <div className="relative">
                <div className="flex items-center justify-between h-10 w-40 px-2 bg-force/10 rounded cursor-pointer" onClick={() => setOpenDropDown(!openDropDown)}>
                  <h6 className="pr-3">{dropDownValue}</h6>
                  <i className="bi bi-caret-down-fill"></i>
                </div>

                <div className={`absolute left-0 top-10 bg-force-black w-full max-h-60 overflow-auto space-y-1.5 py-2 transition-all duration-75 ${openDropDown ? "translate-y-0 scale-y-100" : "scale-y-0 -translate-y-1/2"}`}>
                  <h6 className="py-1.5 px-2 cursor-pointer" onClick={() => updateDropdownValue("All")}>All</h6>
                  <h6 className="py-1.5 px-2 cursor-pointer" onClick={() => updateDropdownValue("Borrow")}>Borrow</h6>
                  <h6 className="py-1.5 px-2 cursor-pointer" onClick={() => updateDropdownValue("Liquidated Borrow")}>Liquidated Borrow</h6>
                  <h6 className="py-1.5 px-2 cursor-pointer" onClick={() => updateDropdownValue("Redeem")}>Redeem</h6>
                </div>
              </div>
            </div>
          </section>

          <section className="grid grid-cols-12 text-sm px-2 py-1 pb-2 text-center">
            <aside className="col-span-1 text-left px-2">ID</aside>
            <aside className="col-span-3 text-left px-2">Type</aside>
            <aside className="col-span-1 px-2">Txn Hash</aside>
            <aside className="col-span-1 px-2">Block</aside>
            <aside className="col-span-1 px-2">From</aside>
            <aside className="col-span-2 px-2">Amount</aside>
            <aside className="col-span-1 px-2">To</aside>
            <aside className="col-span-2 px-2">Date</aside>
          </section>

          <section className="flex-1 h-full overflow-auto">
            {tokens.map((token, index) => (
              <div key={index} className="grid grid-cols-12 text-sm py-3 px-2 text-center">
                <aside className="col-span-1 text-left px-2">{index + 1}</aside>

                <aside className="col-span-3 text-left px-2 flex items-center gap-x-3">
                  <div className="rounded-full bg-white h-7 w-7 flex items-center justify-center">
                    <img src={token.logo} alt={token.name} className="h-4" />
                  </div>

                  <div className="">
                    <h5 className="text-base">{token.type}</h5>
                    <h5 className="text-xs text-gray-500">{token.symbol}</h5>
                  </div>
                </aside>

                <aside className="col-span-1 px-2">0x963...7996</aside>

                <aside className="col-span-1 px-2">38589105</aside>
                <aside className="col-span-1 px-2">0xe78...e245</aside>
                <aside className="col-span-2 px-2">{token.balance}</aside>
                <aside className="col-span-1 px-2">0x2E3...7f36</aside>
                
                <aside className="col-span-2 px-2">Jan-27-2023 01:51:59</aside>
              </div>
            ))}
          </section>
        </div>
      </div>
    </section>
  )
}

export default HistoryPage